<template>
  <b-modal
    id="modal-chart"
    ref="modal-chart"
    :title="`Chart ${label}`"
    size="lg"
    centered
    okOnly
    okTitle="Tutup"
    @show="onShow"
  >
    <b-row>
      <b-col lg="12">
        <apexchart type="bar" :options="chart.chartOptions" :series="chart.series"></apexchart>
      </b-col>
      <b-col lg="12">
        <table class="table mt-4">
          <thead>
            <tr>
              <th>EQUIPMENT</th>
              <th>PART</th>
              <th>METODE</th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in tableData" :key="index">
              <td>{{ row.nama_equipment }}</td>
              <td>{{ row.nama_part }}</td>
              <td>
                <ul>
                  <li
                    v-for="(m, indexMetode) in row.metode_inspeksi"
                    :key="`${index}_${indexMetode}`"
                  >
                    {{ m }}
                  </li>
                </ul>
              </td>
              <td>{{ row.status }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalChart',
  props: {
    label: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: {
        series: [
          {
            name: 'Terealisasi',
            data: [0, 0],
          },
          {
            name: 'Belum Terealisasi',
            data: [0],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          colors: [
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
            '#69d2e7',
          ],
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: ['TERENCANA', 'TIDAK TERENCANA'],
          },
        },
      },

      tableData: [],
    }
  },
  methods: {
    onShow() {
      this.chart.series = [
        {
          name: 'Terealisasi',
          data: [0, 0],
        },
        {
          name: 'Belum Terealisasi',
          data: [0],
        },
      ]

      this.data.forEach(row => {
        if (row._cellVariants[this.label] === 'dark') {
          this.chart.series[1].data[0] = this.chart.series[1].data[0] + 1
        } else if (
          row._cellVariants[this.label] === 'success' ||
          row._cellVariants[this.label] === 'warning'
        ) {
          this.chart.series[0].data[0] = this.chart.series[0].data[0] + row[this.label]
        } else if (row._cellVariants[this.label] === 'primary') {
          this.chart.series[0].data[1] = this.chart.series[0].data[1] + row[this.label]
        }
      })

      this.tableData.splice(0, this.tableData.length)
      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }

      const grouped = groupBy(this.data, 'nama_equipment')

      for (const key of Object.keys(grouped)) {
        grouped[key].forEach((g, index) => {
          if (index == 0) {
            this.tableData.push({
              nama_equipment: g.nama_equipment,
              nama_part: g.nama_part,
              metode_inspeksi: g.metode_inspeksi,
              status: g.program,
            })
          } else {
            this.tableData.push({
              nama_equipment: null,
              nama_part: g.nama_part,
              metode_inspeksi: g.metode_inspeksi,
              status: g.program,
            })
          }
        })
      }
    },
  },
}
</script>
