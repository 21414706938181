<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Inspection Plant'">
        <template v-slot:preview>
          <b-row>
            <b-col lg="3" md="3">
              <b-form-group id="pabrik_id_label" label="Pabrik :" label-for="pabrik_id">
                <b-form-select
                  id="pabrik_id"
                  v-model="filter.pabrik"
                  :options="pabrik"
                  @change="choosePabrik"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="3">
              <b-form-group id="plant_id_label" label="Plant :" label-for="plant_id">
                <b-form-select
                  id="plant_id"
                  v-model="filter.plant"
                  :options="plant"
                  @change="choosePlant"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="2" md="2">
              <b-form-group id="tahun_id_label" label="Tahun :" label-for="tahun_id">
                <b-form-select v-model="filter.tahun" class="mb-3" @change="chooseYear">
                  <b-form-select-option v-for="(year, index) in years" :value="year" :key="index">{{
                    year
                  }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="2" md="2" class="pt-3">
              <b-button
                class="mt-5"
                type="button"
                size="sm"
                variant="dark"
                :disabled="loading"
                @click="loadData()"
              >
                <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                <!-- <strong>Loading...</strong> -->
                <span v-else class="svg-icon svg-icon-sm svg-icon-white">
                  <i class="flaticon-search"></i>
                </span>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <b-row align-h="around" class="mb-4">
            <b-col cols="5" v-if="equipmentChart.series[0].data.length">
              <h6>JUMLAH TOTAL EQUIPMENT INSPECTION {{ label }} {{ filter.tahun }}</h6>
              <apexchart
                type="bar"
                :options="equipmentChart.chartOptions"
                :series="equipmentChart.series"
              ></apexchart>
            </b-col>
            <b-col cols="6" v-if="total_done.length">
              <h6>JUMLAH TOTAL REALISASI {{ label }} {{ filter.tahun }}</h6>
              <apexchart type="pie" :options="total_doneOptions" :series="total_done"> </apexchart>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mb-2" v-if="akses">
              <b-button
                variant="outline-dark"
                @click="$router.push(`/inspectionplan/add/${filter.tahun}`)"
              >
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>

              <b-button
                class="ml-2"
                variant="outline-success"
                @click="$router.push(`/inspectionplan/import/${filter.tahun}`)"
              >
                <span class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon-upload"></i>
                </span>
                Import Excel
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="sm"
                size="sm"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <template #thead-top>
                  <b-tr>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_equipment"
                        type="search"
                        placeholder="Cari Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_part"
                        type="search"
                        placeholder="Cari Part..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.metode_inspeksi"
                        type="search"
                        placeholder="Cari Metode..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="13"></b-th>
                  </b-tr>
                </template>

                <template #head(Jan)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Feb)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Mar)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Apr)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(May)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Jun)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Jul)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Aug)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Sep)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Oct)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Nov)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #head(Dec)="data">
                  <a @click="openChart(data.label)">{{ data.label.toUpperCase() }}</a>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(metode_inspeksi)="data">
                  <ul class="ml-3">
                    <li v-for="(row, index) in data.value" :key="index">
                      {{ row }}
                    </li>
                  </ul>
                </template>

                <template #cell(actions)="data">
                  <span
                    @click="$router.push('/inspectionplan/edit/' + data.item.id_inspection_plan)"
                    v-if="data.item._rowVariant !== 'success'"
                    class="svg-icon svg-icon-sm svg-icon-primary"
                  >
                    <i class="flaticon2-pen text-primary"></i>
                  </span>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <br />
          <b-row>
            <h4>Catatan :</h4>
            <b-col sm="12">
              <span class="svg-icon svg-icon-sm svg-icon-dark">
                <i class="flaticon2-hexagonal text-dark"></i>
              </span>
              Pekerjaan tahap direncanakan (Plan)
            </b-col>
            <b-col sm="12">
              <span class="svg-icon svg-icon-sm svg-icon-success">
                <i class="flaticon2-hexagonal text-success"></i>
              </span>
              Pekerjaan direncanakan dan dikerjakan sesuai dengan waktu rencana
            </b-col>
            <b-col sm="12">
              <span class="svg-icon svg-icon-sm svg-icon-warning">
                <i class="flaticon2-hexagonal text-warning"></i>
              </span>
              Pekerjaan direncanakan dan dikerjakan tidak sesuai dengan waktu rencana
            </b-col>
            <b-col sm="12">
              <span class="svg-icon svg-icon-sm svg-icon-primary">
                <i class="flaticon2-hexagonal text-primary"></i>
              </span>
              Pekerjaan telah dikerjakan tanpa planing
            </b-col>
          </b-row>
          <b-modal
            id="modal-inspection-plan"
            ref="modal"
            :title="`Realisasi Inspeksi ${form.nomor_equipment} ${form.metode_inspeksi}`"
            size="xl"
            @ok="handleOk"
          >
            <b-form class="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="8"
                id="rencana_inspeksi_label"
                label="Rencana Inspeksi :"
                label-for="rencana_inspeksi"
              >
                <b-form-input
                  id="rencana_inspeksi"
                  v-model="form.rencana_inspeksi"
                  type="date"
                  placeholder="Enter Text..."
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="8"
                id="realisasi_inspeksi_label"
                label="Realisasi Inspeksi :"
                label-for="realisasi_inspeksi"
              >
                <b-form-input
                  id="realisasi_inspeksi"
                  v-model="form.realisasi_inspeksi"
                  type="date"
                  placeholder="Enter Text..."
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-modal>
          <modalChart v-if="openModalChart" :label="modalChart.label" :data="modalChart.data" />
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import itemTemplate from '@/view/pages/item-template.vue'
import modalChart from './modalChart.vue'
import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      equipmentChart: {
        series: [
          {
            name: 'Total',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              distributed: true,
              borderRadius: 4,
              horizontal: false,
            },
          },
          colors: [
            '#33b2df',
            '#546E7A',
            '#d4526e',
            '#13d8aa',
            '#A5978B',
            '#2b908f',
            '#f9a3a4',
            '#90ee7e',
            '#f48024',
            '#69d2e7',
          ],
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: [],
          },
        },
      },

      total_done: [],
      total_doneOptions: {
        chart: {
          type: 'pie',
        },
        labels: [
          'Inspection Plan',
          'Sudah Realisasi dan Sesuai Plan',
          'Pekerjaan telah dikerjakan tanpa planing',
          'Sudah Realisasi tapi Lewat Waktu',
        ],
        textAnchor: 'middle',
        dataLabels: {
          enabled: true,
          /* eslint-disable */
          formatter: function(_, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
          /* eslint-enable */
        },
        colors: ['#181C32', '#1BC5BD', '#33b2df', '#FFA800', '#c82333'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: '18px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: '#fff',
                  offsetY: 16,
                  formatter: function(val) {
                    return val
                  },
                },
                total: {
                  show: false,
                },
              },
            },
          },
        },
        legend: {
          position: 'right',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },

      filter: {
        pabrik: null,
        plant: null,
        tahun: null,
      },

      label: null,

      pabrik: [],
      plant: [],
      method: [
        { id: 'Visual', label: 'Visual' },
        { id: 'Ultrasonic Thickness', label: 'Ultrasonic Thickness' },
        { id: 'Radiography', label: 'Radiography' },
        { id: 'Ultrasonic', label: 'Ultrasonic' },
        { id: 'Liquid Penetrant', label: 'Liquid Penetrant' },
        { id: 'Magnetic Particle', label: 'Magnetic Particle' },
        { id: 'Eddy Current', label: 'Eddy Current' },
        { id: 'Leak Test', label: 'Leak Test' },
        {
          id: 'Hydrostatic Pressure Test',
          label: 'Hydrostatic Pressure Test',
        },
        { id: 'Thermography', label: 'Thermography' },
        { id: 'Hardness Test', label: 'Hardness Test' },
      ],

      sortBy: 'nama_equipment',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'nama_equipment', label: 'Equipment', sortable: true },
        { key: 'nama_part', label: 'Part', sortable: true },
        { key: 'metode_inspeksi', label: 'Metode Inspeksi', sortable: true },
        { key: 'Jan', label: 'Jan', class: 'text-center', sortable: false },
        { key: 'Feb', label: 'Feb', class: 'text-center', sortable: false },
        { key: 'Mar', label: 'Mar', class: 'text-center', sortable: false },
        { key: 'Apr', label: 'Apr', class: 'text-center', sortable: false },
        { key: 'May', label: 'May', class: 'text-center', sortable: false },
        { key: 'Jun', label: 'Jun', class: 'text-center', sortable: false },
        { key: 'Jul', label: 'Jul', class: 'text-center', sortable: false },
        { key: 'Aug', label: 'Aug', class: 'text-center', sortable: false },
        { key: 'Sep', label: 'Sep', class: 'text-center', sortable: false },
        { key: 'Oct', label: 'Oct', class: 'text-center', sortable: false },
        { key: 'Nov', label: 'Nov', class: 'text-center', sortable: false },
        { key: 'Dec', label: 'Dec', class: 'text-center', sortable: false },
        // {
        //   key: "jumlah_terlambat",
        //   label: "Jumlah Terlambat",
        //   class: "text-center"
        // },
        { key: 'actions', label: 'Aksi' },
      ],
      openModalChart: false,
      modalChart: {
        label: '',
        data: [],
      },

      search: {
        nama_equipment: '',
        nama_part: '',
        metode_inspeksi: '',
      },

      form: {
        id_inspection_plan: null,
        nomor_equipment: null,
        rencana_inspeksi: null,
        metode_inspeksi: null,
        realisasi_inspeksi: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      equipment: [],
      part: [],
      damage_mechanism: [],
      ItemEquipment: null,
      ItemPart: null,
      itemTemplate,

      isBusy: false,
      loading: false,
      akses: false,
    }
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2015 }, (value, index) => 2016 + index)
    },
  },
  components: {
    KTCodePreview,
    modalChart,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Inspection Plan', route: '' },
      { title: 'Inspection Plan' },
    ])
  },
  async created() {
    if (this.$route.query.pabrik_id) {
      this.filter.pabrik = Number(this.$route.query.pabrik_id)
    }

    var self = this
    self.urlStorage = ApiService.urlStorage()
    const year = new Date().getFullYear()
    this.filter.tahun = year
    self.plant.push({
      value: null,
      text: '-- Pilih Pabrik --',
    })

    await ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          self.pabrik.splice(0, self.plant.length)
          self.pabrik.push({
            value: null,
            text: '-- Pilih Pabrik --',
          })
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    if (self.filter.pabrik) {
      await ApiService.get(
        '/master/plant/all?page=1&pabrik_id=' +
          Number(self.filter.pabrik) +
          '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plan --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      if (this.$route.query.plant_id) {
        this.filter.plant = Number(this.$route.query.plant_id)
        this.$router.replace({
          name: 'Inspection Plan',
          query: {
            pabrik_id: this.filter.pabrik,
            plant_id: this.filter.plant,
            tahun: this.filter.tahun,
          },
        })

        this.label = this.plant.filter(f => f.value == Number(this.$route.query.plant_id))[0].text
        this.loadData()
      }
    }
  },
  methods: {
    openChart(label) {
      const filter = this.items.filter(f => f[label] !== undefined)
      this.modalChart.data.splice(0, this.modalChart.data.length)
      this.openModalChart = false
      if (!this.items.length && !filter.length) {
        return
      }

      this.$nextTick()
      this.openModalChart = true
      this.modalChart.label = label
      this.modalChart.data = filter

      this.$nextTick(() => {
        this.$bvModal.show('modal-chart')
      })
    },
    moment: function(date) {
      moment.locale('id')
      return moment(date).format('DD/MM/YYYY')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    choosePabrik() {
      var self = this
      this.$router.replace({
        name: 'Inspection Plan',
        query: { pabrik_id: self.filter.pabrik },
      })
      ApiService.get(
        '/master/plant/all?page=1&pabrik_id=' +
          Number(self.filter.pabrik) +
          '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plan --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    choosePlant() {
      this.label = this.plant.filter(f => f.value == this.filter.plant)[0].text
      this.$router.replace({
        name: 'Inspection Plan',
        query: {
          pabrik_id: this.filter.pabrik,
          plant_id: this.filter.plant,
          tahun: this.filter.tahun,
        },
      })
    },
    chooseYear() {
      this.$router.replace({
        name: 'Inspection Plan',
        query: {
          pabrik_id: this.filter.pabrik,
          plant_id: this.filter.plant,
          tahun: this.filter.tahun,
        },
      })
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true
      self.loading = true
      self.items.splice(0, self.items.length)
      await this.timeout(100)
      ApiService.get(
        '/inspectionplan?page=' +
          this.currentPage +
          '&nama_equipment=' +
          this.search.nama_equipment +
          '&nama_part=' +
          this.search.nama_part +
          '&metode_inspeksi=' +
          this.search.metode_inspeksi +
          '&pabrik_id=' +
          this.filter.pabrik +
          '&plant_id=' +
          this.filter.plant +
          '&tahun=' +
          this.filter.tahun +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.akses = data.akses
            self.items.splice(0, self.items.length)
            const variant = ['dark', 'warning', 'primary', 'success']
            data.inspection.forEach(row => {
              let bulan = {}
              for (const key in row) {
                if (variant.includes(row[key])) {
                  bulan[key] = row[key] !== 'dark' ? 1 : null
                  bulan['_cellVariants'] = {
                    [key]: row[key],
                    ...bulan['_cellVariants'],
                  }
                }
              }

              self.items.push({
                id_inspection_plan: row.id_inspection_plan,
                nama_equipment: row.nama_equipment,
                nama_part: row.nama_part,
                metode_inspeksi: row.metode_inspeksi,
                jumlah_terlambat: row.jumlah_terlambat,
                program: row.program,
                ...bulan,
              })
            })

            self.total_done = [0, 0, 0, 0]
            self.items.forEach(row => {
              for (const key in row._cellVariants) {
                if (row._cellVariants[key] == 'dark') {
                  self.total_done[0]++
                } else if (row._cellVariants[key] === 'warning') {
                  self.total_done[2] += row[key]
                } else if (row._cellVariants[key] === 'primary') {
                  self.total_done[3] += row[key]
                } else if (row._cellVariants[key] === 'success') {
                  self.total_done[1] += row[key]
                }
              }
            })

            self.totalRows = data.data.total
            self.totalPerPage = data.data.data.length
            self.isBusy = false
            self.loading = false
          }
        })
        .catch(response => {
          console.log(response)
        })

      ApiService.get(
        '/inspectionplan/chart?' +
          'pabrik_id=' +
          this.filter.pabrik +
          '&plant_id=' +
          this.filter.plant +
          '&tahun=' +
          this.filter.tahun +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.equipmentChart.series[0].data.splice(0, self.equipmentChart.series[0].data.length)
            self.equipmentChart.chartOptions.xaxis.categories.splice(
              0,
              self.equipmentChart.chartOptions.xaxis.categories.length
            )
            const equipment = data.equipment
            equipment.forEach(row => {
              self.equipmentChart.series[0].data.push(row.total)
              self.equipmentChart.chartOptions.xaxis.categories.push(row.nama_equipment)
            })
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    resetModal() {
      this.form.id_inspection_plan = null
      this.form.nomor_equipment = null
      this.form.rencana_inspeksi = null
      this.form.metode_inspeksi = null
      this.form.realisasi_inspeksi = null
    },
    editModal(data) {
      this.form.id_inspection_plan = data.id_inspection_plan
      this.form.nomor_equipment = data.nomor_equipment
      this.form.rencana_inspeksi = data.rencana_inspeksi
      this.form.metode_inspeksi = data.metode_inspeksi
      this.form.realisasi_inspeksi = data.realisasi_inspeksi
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      var self = this
      self.loading = true
      const id_inspection_plan = self.form.id_inspection_plan
      const rencana_inspeksi = self.form.rencana_inspeksi
      const realisasi_inspeksi = self.form.realisasi_inspeksi

      ApiService.post('/inspectionplan/update/' + id_inspection_plan, {
        rencana_inspeksi: rencana_inspeksi,
        realisasi_inspeksi: realisasi_inspeksi,
      })
        .then(({ data }) => {
          if (data.status === 'ok') {
            self.loadData()
            // Hide the modal manually
            self.resetModal()
            self.$nextTick(() => {
              self.$bvModal.hide('modal-inspection-plan')
            })
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
    // popoverMethod(data) {
    // 	// Returns the content as a string
    // 	// Will be called each time the popover is opened
    // 	var html =
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Nama Equipment : </b></b-col><b-col>' +
    // 		data.nama_equipment +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Nama Part : </b></b-col><b-col>' +
    // 		data.nama_part +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Damage Mechanism : </b></b-col><b-col>' +
    // 		data.damage_mechanism +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Rencana Inspeksi : </b></b-col><b-col>' +
    // 		data.rencana_inspeksi +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Metode Inspeksi : </b></b-col><b-col>' +
    // 		data.metode_inspeksi +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Realisasi Inspeksi : </b></b-col><b-col>' +
    // 		data.realisasi_inspeksi +
    // 		"</b-col></b-row><br>";
    // 	html +=
    // 		'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Dibatalkan : </b></b-col><b-col>' +
    // 		data.ditunda +
    // 		"</b-col></b-row><br>";
    // 	if (data.ditunda == "ya") {
    // 		html +=
    // 			'<b-row class="mb-2"><b-col sm="3" class="text-sm-right"><b>Alasan Ditunda : </b></b-col><b-col>' +
    // 			data.alasan_ditunda +
    // 			"</b-col></b-row>";
    // 	}

    // 	return html;
    // },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
    },
    itemSelectedPart: function(value) {
      this.form.part_id = value.id
    },
    inputChangePart(text) {
      // your search method
      var self = this
      ApiService.get('/master/part?page=1&nama_part=' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var part = data.data.data
            self.part.splice(0, self.part.length)

            for (let i = 0; i < part.length; i++) {
              self.part.push({
                id: part[i].id_part,
                name: part[i].nama_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      // this.modalForm.part = text;
    },
  },
}
</script>
